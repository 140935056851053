input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.btn-main{
    color: #fff;
    padding: 8px 20px;
    border-radius: 14px;
    background-color: #00b0ad;
    cursor: pointer;
    font-weight: 500;
    transition: all 330ms ease-in-out;
    
}
.btn-main:hover{
    color: #fff !important;
    text-decoration: none;
    transform: scale(1.05);
}
.btn-main-yellow{
    background-color: #ffc84d;
    color: #000;
}
.btn-main-blue{
    background-color: #1583a3;
}


nav{
    box-shadow:0px 22px 17.5px rgba(0,0,0,0.13);
    background-color: #11557b;
    padding: 14px 0 !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    z-index: 20;
    .nav-logo{
        height: 40px;
        padding-left: 30px;
    }
    .navbar-nav{
        margin-left: auto;
    }
    .nav-link{
        color: #f5f9fb;;
        font-weight: 600;
        margin: 0 20px;
        opacity: 0.6;
        &:hover{
            opacity:1;
        }
        &.btn-main{
            padding: 8px 20px !important;
            margin-left: 14px;
            opacity: 1;
        }
    }
}

.navbar-toggler{
    margin-right: 30px;
}
.navbar-toggler-icon {
    box-shadow: none;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 0.8)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
  }
  



.Home{
    background-image: url("/img/bg.png");
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 50px 12%;
    .head{
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        margin-bottom: 40px;
        img{
            height: 50px;
        }
        div{
            text-align: center;
            margin: 0 40px;
            h2{
                margin-bottom: 8px;
            }
        }
    }
    .row{
        margin-bottom: 30px;
    }

    .blue-bx{
        border-radius: 26px;
        background-color: #11557b;
        padding: 24px;
        color: #1cdede;
        height: 100%;
    }
    .blue-bx-lg{
        position: relative;
        img{height: 60px;margin: 16px 0;}
        .info{
            color: #1cdede;
            font-weight: 600;
            margin-bottom: 14px;
            h3{
                color: #fff;
                margin-bottom: 3px;
            }
            h5{
                color: #fff;
                margin-bottom: 0;
            }
        }
        .btn-main{
            display: block;
            text-align: center;
        }
        .back{
            position: absolute;
            height: 55%;
            width: auto;
            right: 0;
            top: 45%;
            transform: translateY(-50%);
        }
    }

    .blue-bx-sm{
        font-weight: 600;
        font-size: 24px;
        position: relative;
        img{
            position: absolute;
            height: 60%;
            right: 0;
            top: 50%;
            transform: translate(-20% , -50%);
        }
        h2{
            margin:6px 0;
            color: #fff;
        }
        small{font-size: 16px;}
    }

    .blue-bx-stats{
        .drow{
            display: flex;
            justify-content: space-between  ;
            margin-bottom: 5px;
        }
    }
    .blue-bx-power{
        padding: 0;
       
        position: relative;
        background-image: url("img/sm2.png");
        background-position: top right;
        background-size: cover;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .blue{
            color: #11557b;
            font-weight: bold;
            font-size: 22px;
            margin-left: 22px;
        }
        .black{
            color: #fff;
            font-weight: bold;
            font-size: 28px;
            margin-left: 20px;
        }
    }
}




.Stake{
    background-image: url("/img/bg.png");
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 50px 20%;
   

    .stake-btn-outer{
        
        margin: 60px 0px 0;
        // display: flex;
        // .stake-btm{
        //     width: 33.33%;
        // }
        .col-md-4{margin: 0;padding: 0;}
        .col-md-4{
            &:first-child{
                .stake-btm{
                    border-top-left-radius: 26px;
                    border-bottom-left-radius: 26px;
                    .gf1{
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 10vw;
                        transform: translate(-40%,-50%);
                    }
                }
            }
            &:last-child{
                .stake-btm{
                    border-top-right-radius: 26px;
                    border-bottom-right-radius: 26px;
                    .gf2{
                        position: absolute;
                        right: 0;
                        top: 0;
                        width: 10vw;
                        transform: translate(40%,-50%);
                    }
                }
            }
        }
        
        .stake-btm-blue{    
            background-color: #11557b;
            padding: 35px 24px 60px;
            color: #fff;
            height: 100%;
            text-align: center;
            position: relative;
            h6{
                span{
                    color: #1cdede;
                    font-weight: 400;
                }
            }
            .participant{
                margin:20px 0 30px;
                border-bottom: 2px solid #1cdede;
                padding-bottom: 30px;
                div{
                    margin-bottom: 8px;
                }
            }
            .btn-main{
                width: 200px;
                text-align: center;
                margin: 30px auto;
                display: block;
            }
            .time{
                display: flex;
                justify-content: space-between;
                width: 70%;
                margin: auto;
                font-size: 22px;
                font-weight: 500;
                span{
                    font-size: 18px;
                    color: #00b0ad;
                }
            }
        }
        .stake-btm-middle{
            padding: 5px 24px 60px;
            color: #fff;
            height: 100%;
            text-align: center;
            background: url("img/middlebg.png");
            background-position: bottom;
            background-size: cover;
            .heading{
                .lwinnerrowouter{
                    .lwinnerrow{margin:16px 0px 30px;
                        display: flex;
                        justify-content: space-between;
                        .blue{
                            color: #11557b;
                            font-weight: 600;
                        }
                        .token{
                            // text-decoration: underline;
                            cursor: pointer;
                            .btn-main{
                                margin-right: 6px !important;
                                
             
                            }
                        }
                    }
                }
                h2 {
                    font: 13px sans-serif;
                    margin-top: 30px;
                    text-align: center;
                    text-transform: uppercase;
                }
    
                h2.no-span {
                    display: table;
                    white-space: nowrap;
                    color: #11557b;
                    font-size: 18px;
                    font-weight: 600;
                }
                h2.no-span:before,
                h2.no-span:after {
                    border-top: 2px solid #11557b;
                    content: '';
                    display: table-cell;
                    position: relative;
                    top: 0.5em;
                    width: 45%;
                }
                h2.no-span:before {
                    right: 1.5%;
                }
                h2.no-span:after {
                    left: 1.5%;
                }
            }

            .heading-btm{
                margin: 30px 0 16px;
                .lwinnerrow{
                    font-size: 20px;
                    font-weight: 500;
                    span{
                        color: #9feff0;
                        font-size: 16px;
                    }
                }
            }
              
        }
    }
}
.stake-bx{
    .stakel{
        width: 90px;
        display: block;
        margin: auto;
        transform: translateY(50%);
    }
    .blue-bx{
        border-radius: 26px;
        background-color: #11557b;
        padding: 24px;
        color: #1cdede;
        height: 100%;
        padding-top: 50px;
        
        .header{
            display: flex;
            justify-content: space-between;
            .headerbtm{
                color: #ffff;
                font-weight: 500;
                h5{
                    font-size: 16px;
                    font-weight: 300;
                    color: #1cdede;
                }
                b{
                    color: #1cdede;
                }
            }
        }

        .ip_outer{
            margin-top: 40px;
            .ip_row{
                display: flex;
                justify-content: space-between;
                margin-bottom: 20px;
                .btn-main{min-width: 200px;text-align: center}
                .form-control-outer{
                    margin-right: 10px;
                    position: relative;
                    background: #0e4564;
                    width: 100%;
                    border-radius: 14px;
                    // padding:  14px    ;
                    padding-left: 12px;
                    padding-top: 4px;
                    &:before{
                        content: "";
                        position: absolute;
                        left:14px;
                        height: 20px;
                        width: 1px;
                        background: #1cdede;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                    .form-control{
                        background: transparent;
                        box-shadow: none;
                        border: none;
                        border-radius: 0px;
                        color: #fff;
                        // padding: 0;
                        padding-bottom: 10px;
                    }
                }
            }
        }
    }
}

