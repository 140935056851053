@media (min-width: 1351px) and (max-width: 1649px) {

}



@media (min-width: 1025px) and (max-width: 1350px) {
    
    .Home{
        .blue-bx-lg{
            h3{font-size: 22px;}
            .info{
                font-size: 16px;
                h3{
                    font-size: 18px;
                }
            }
        }
        .blue-bx-sm{
            font-size: 20px;
            h2{
                font-size: 24px;
            }
        }
        .blue-bx-stats{
            h2{font-size: 22px;}
        }
    }


    .Stake{
        padding: 50px 12%;
        .lwinnerrow{
            flex-direction: column;
            .token{
                margin-top: 10px;
            }
        }
    }
    

    
}



@media (min-width: 992px) and (max-width: 1024px) {
    .Home{
        .blue-bx-lg{
            h3{font-size: 22px;}
            .info{
                font-size: 16px;
                h3{
                    font-size: 18px;
                }
            }
        }
        .blue-bx-sm{
            font-size: 20px;
            h2{
                font-size: 24px;
            }
        }
        .blue-bx-stats{
            h2{font-size: 22px;}
        }
    }

    .Stake{
        padding: 50px 10%;
        .stake-btn-outer{
            
            .gf1{width: 14vw !important;}
            .gf2{width: 14vw !important;}
        }
        .lwinnerrow{
            flex-direction: column;
            .token{
                margin-top: 10px;
            }
        }
    }

    
}



@media (min-width: 768px) and (max-width: 991px) {
    .Home{
        padding: 50px 6%;
        .blue-bx-lg{
            h3{font-size: 20px;}
            .info{
                font-size: 14px;
                h3{
                    font-size: 16px;
                }
            }
        }
        .blue-bx-sm{
            font-size: 18px;
            h2{
                font-size: 22px;
            }
        }
        .blue-bx-stats{
            h2{font-size: 20px;}
        }
    }

    .Stake{
        padding: 50px 10%;
        .stake-btn-outer{
            .stake-btm-blue .btn-main{width: 120px;}
            .gf1{width: 14vw !important;}
            .gf2{width: 14vw !important;}
        }
        .lwinnerrow{
            flex-direction: column;
            .token{
                margin-top: 10px;
            }
        }
    }
    .stake-bx{
        .header{
            .headerbtm{
                font-size: 14px;
            }
        }
        .ip_row{
            flex-direction: column;
            margin-bottom: 30px;
            .btn-main{
                margin-top: 10px;
            }
        }
    }
}



@media (min-width: 576px) and (max-width: 767px) {
    .navbar-collapse{
        background-color: rgba(17, 85, 123, 0.8);
        padding-bottom: 20px;
        padding-top: 10px;
    }
    .Home{
        padding: 50px 8%;
        .row{
            margin-bottom: 0;
            .col-sm-12{
                margin-bottom: 30px;
            }
        }
        .blue-bx-lg{
            h3{font-size: 20px;}
            .info{
                font-size: 14px;
                h3{
                    font-size: 16px;
                }
            }
        }
        .blue-bx-sm{
            font-size: 18px;
            h2{
                font-size: 22px;
            }
        }
        .blue-bx-stats{
            h2{font-size: 20px;}
        }
        .blue-bx-power{
            position: relative;
            min-height: 180px;
        }
    }

    .Stake{
        padding: 50px 20%;
        .lwinnerrow{
            flex-direction: column;
            .token{
                margin-top: 10px;
            }
        }
        .stake-btn-outer{
            .stake-btm-blue .btn-main{width: 160px;}
            .gf1{width: 18vw !important;}
            .gf2{width: 18vw !important;}
            .col-md-4{
                &:first-child{
                    .stake-btm{
                        border-top-left-radius: 26px;
                        border-bottom-left-radius: 0;
                        border-top-right-radius: 26px;
                    }
                }
                &:last-child{
                    .stake-btm{
                        border-top-right-radius: 0;
                        border-bottom-right-radius: 26px;
                        border-bottom-left-radius: 26px;
                    }
                }
            }
        }
    }
    .stake-bx{
        .header{
            .headerbtm{
                font-size: 14px;
            }
        }
        .ip_row{
            flex-direction: column;
            margin-bottom: 30px;
            .btn-main{
                margin-top: 10px;
            }
        }
    }
}



@media (min-width: 448px) and (max-width: 575px) {
    .navbar-collapse{
        background-color: rgba(17, 85, 123, 0.8);
        padding-bottom: 20px;
        padding-top: 10px;
    }
    
    .Home{
        padding: 50px 8%;
        .row{
            margin-bottom: 0;
            .col-sm-12{
                margin-bottom: 30px;
            }
        }
        .blue-bx-lg{
            h3{font-size: 20px;}
            .info{
                font-size: 14px;
                h3{
                    font-size: 16px;
                }
            }
        }
        .blue-bx-sm{
            font-size: 18px;
            h2{
                font-size: 22px;
            }
        }
        .blue-bx-stats{
            h2{font-size: 20px;}
        }
        .blue-bx-power{
            min-height: 180px;
        }
    }

    .Stake{
        padding: 50px 10%;
        .lwinnerrow{
            flex-direction: column;
            .token{
                margin-top: 10px;
            }
        }
        .stake-btn-outer{
            .stake-btm-blue .btn-main{width: 160px;}
            .gf1{width: 18vw !important;}
            .gf2{width: 18vw !important;}
            .col-md-4{
                &:first-child{
                    .stake-btm{
                        border-top-left-radius: 26px;
                        border-bottom-left-radius: 0;
                        border-top-right-radius: 26px;
                    }
                }
                &:last-child{
                    .stake-btm{
                        border-top-right-radius: 0;
                        border-bottom-right-radius: 26px;
                        border-bottom-left-radius: 26px;
                    }
                }
            }
        }
    }
    .stake-bx{
        .header{
            .headerbtm{
                font-size: 14px;
            }
        }
        .ip_row{
            flex-direction: column;
            margin-bottom: 30px;
            .btn-main{
                margin-top: 10px;
            }
        }
    }
}



@media (max-width: 447px) {
    .navbar-collapse{
        background-color: rgba(17, 85, 123, 0.8);
        padding-bottom: 20px;
        padding-top: 10px;
    }
    .Home{
        padding: 50px 8%;
        .head{
            div{margin: 0 10px;}
        }
        .row{
            margin-bottom: 0;
            .col-sm-12{
                margin-bottom: 30px;
            }
        }
        .blue-bx-lg{
            h3{font-size: 20px;}
            .info{
                font-size: 14px;
                h3{
                    font-size: 16px;
                }
            }
        }
        .blue-bx-sm{
            font-size: 18px;
            h2{
                font-size: 22px;
            }
        }
        .blue-bx-stats{
            h2{font-size: 20px;}
        }
        .blue-bx-power{
            position: relative;
            min-height: 180px;

        }
    }

    .Stake{
        padding: 50px 10%;
        .lwinnerrow{
            flex-direction: column;
            .token{
                margin-top: 10px;
            }
        }
        .stake-btn-outer{
            .stake-btm-blue .btn-main{width: 160px;}
            .gf1{width: 18vw !important;}
            .gf2{width: 18vw !important;}
            .col-md-4{
                &:first-child{
                    .stake-btm{
                        border-top-left-radius: 26px;
                        border-bottom-left-radius: 0;
                        border-top-right-radius: 26px;
                    }
                }
                &:last-child{
                    .stake-btm{
                        border-top-right-radius: 0;
                        border-bottom-right-radius: 26px;
                        border-bottom-left-radius: 26px;
                    }
                }
            }
        }
    }
    .stake-bx{
        .header{
            .headerbtm{
                font-size: 14px;
            }
        }
        .ip_row{
            flex-direction: column;
            margin-bottom: 30px;
            .btn-main{
                margin-top: 10px;
            }
        }
    }
}